<style lang="scss" scoped>
</style>
<template>
  <div class="">
    <p>用戶協議：</p>
    <div id="box1"></div>
    <el-button
      type="primary"
      style="margin-top: 40px; margin-left: 20px"
      @click="submit"
      >提交</el-button
    >
  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
  getCurrentInstance,
  ref,
  onMounted,
} from "vue";
import E from "wangeditor";
import i18next from "i18next";
import defineLanguage from "@/plugins/defineLanguage";
import editorConfig from "@/plugins/editorConfig";


export default defineComponent({
  setup() {
    const proxy = getCurrentInstance().appContext.config.globalProperties;
    const { $http,$message } = proxy;
    const state = reactive({
      infoData: "",
    });
    let editor = null;

    const init = async () => {
      const res = await $http.getClause({ type: "treaty" });
      state.infoData = res.data;
      editor.txt.html(state.infoData.treaty);
    };

    init();

    onMounted(() => {
      editor = new E("#box1");

      Object.assign(editor.config, editorConfig);
      // 选择语言
      editor.config.lang = "fanti";
      // 自定义语言
      editor.config.languages["fanti"] = defineLanguage;
      // 引入 i18next 插件
      editor.i18next = i18next;
      editor.create();
      editor.config.customUploadImg = async (resultFiles, insertImgFn) => {
        // resultFiles 是 input 中选中的文件列表
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
        const formdata = new FormData();
        formdata.append("file", resultFiles[0]);
        // 上传图片，返回结果，将图片插入到编辑器中
        const { data } = await $http.upload(formdata);
        insertImgFn(data.file_path);
      };
      // editor.txt.html(data.details);
    });

    const submit = async () => {
      const res = await $http.editClause({
        type: "treaty",
        content: editor.txt.html(),
      });
      if (res.status !== 200) return;
      $message.success("操作成功");
    };

    return { state, submit };
  },
});
</script>
